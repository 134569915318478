import { useTranslation } from 'react-i18next';
import { Avatar, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import SvgIcon from '@mui/material/SvgIcon';
// import { useEthBalanceQuery } from '../Ethereum';
import { makeStyles } from 'tss-react/mui';
import { BigNumber, utils } from 'ethers';

import { readLocalStorage } from 'helper/utils';
import LanguageSelect from '../../components/Language/LanguageSelect';

const AppTopbar: React.FC = () => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const {
    // wallet,
    // operatorpicture,
    moneta,
    operatorname,
    email = '',
  } = readLocalStorage();

  // const { data } = useEthBalanceQuery(email);
  // const wei =
  //   typeof BigInt === 'function'
  //     ? BigNumber.from(utils.hexlify(BigInt(`${data ? data.balance : 0}`)))
  //     : data
  //     ? data.balance
  //     : 0;
  // const balance = utils.formatEther(wei);

  return (
    <>
      <div className={classes.appBar}>
        <Avatar
          className={classes.avatar}
          variant="circular"
          alt={operatorname}
          src={'/api/get/avatar.php?moneta=' + moneta}
        />
        <div className={cx(classes.languageSelect)}>
          <LanguageSelect />
        </div>
      </div>
      {/* <Typography
        color="primary"
        className={cx(classes.balance)}
        component="div"
        variant="caption"
        align="center"
      >
        {`${t('user.balance')} :`}
        <NumericFormat
          displayType="text"
          thousandSeparator=","
          decimalScale={5}
          value={balance}
        />{' '}
        <SvgIcon sx={{ verticalAlign: 'middle' }} viewBox="0 0 288 288">
          <g fill="#328332" stroke="#328332">
            <path d="m2.4 98.8 65-27.4 63 28.1-63.1-99.5zm.2 30.4 64.9 37.6 66.2-37.6-65.6 91.3z"></path>
            <path d="m67.7 84.8-67.7 28.5 67.7 37.6 65.8-36.8z"></path>
          </g>
        </SvgIcon>
      </Typography> */}
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  appBar: {
    position: 'absolute',
    top: 8,
    right: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // width: 368,
    // height: 28,
    background: 'transparent',
    zIndex: 1,
  },
  balance: {
    position: 'absolute',
    top: 36,
    right: 3,
    fontSize: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 6,
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginBottom: theme.spacing(1),
    // '& svg': {
    //   width: theme.spacing(6),
    //   height: theme.spacing(6),
    // },
    marginLeft: 'auto',
    marginRight: 8,
    display: 'inline-block',
    float: 'right',
  },
  languageSelect: {
    display: 'inline-block',
    float: 'right',
  },
}));

export default AppTopbar;
