import { useEffect, useRef, useState, useCallback } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import {
  Typography,
  Box,
  Container,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Link,
  TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import LoginSharpIcon from '@mui/icons-material/LoginSharp';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

import { executePromise } from '@knowins/notifier';
import { useWallet, useLogin, useLoginStatus } from 'api';

import {
  walletconnect,
  useWalletConnect,
  onWalletConnect,
  getAccount as getConnectWallet,
} from 'features/WalletConnect/hook/useWalletConnect';
import WalletConnect from 'features/WalletConnect/WalletConnect';

import useMetaMask, {
  metamask,
  onMetaMaskConnect,
  loginRequest,
  getAccount as getMetaMaskWallet,
} from 'features/MetaMask/hook/useMetaMask';
import MetaMask from 'features/MetaMask/MetaMask';

import appStore from 'store/appStore';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [loginFailureCount, setLoginFailure] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      username: '',
      password: '',
      captcha: '',
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  // Support login with wallet connect
  const { mutateAsync: connectWallet } = useWallet();

  const connect = (inputs: any) => {
    return executePromise(connectWallet(inputs), {
      loading: 'status.connecting_wallet',
      success: (res: any) => {
        console.log('wc res.........%s', res);
        return '';
      },
      error: (r) => {
        console.log(r);
        return '';
      },
    });
  };

  useMetaMask({
    version: 0,
    connect: async (inputs, skipLoginRequest) => {
      if (!skipLoginRequest) {
        const result = await loginRequest();
        if (result.success) {
          return connect({
            ...inputs,
            s: {
              t: 'eip191',
              s: result.signature,
            },
          });
        }
      } else {
        return connect(inputs);
      }
    },
    delegation: true,
  });

  useWalletConnect({
    version: 0,
    connect,
    delegation: true,
  });

  // Support legacy login
  const { status, mutateAsync: login } = useLogin();
  const onSubmit = (data: {
    email: string;
    username: string;
    password: string;
    captcha: string;
  }) => {
    const { email, password, captcha: securitycode } = data;
    let username = data.username;
    if (username.length === 0) {
      username = email.split('@')[0];
    }

    if (status !== 'loading') {
      executePromise(
        login({
          email: email.toLowerCase(),
          username: username.toLowerCase(),
          password,
          securitycode,
        }),
        {
          loading: 'status.logging_in',
          error: (r) => {
            console.log(r);
            setLoginFailure(r.failure);
            return '';
          },
        }
      );
    }

    return false;
  };

  // Check login status
  const { data: config } = useLoginStatus();
  useEffect(() => {
    if (config?.login) {
      // Use this wallet for reports that auto-fill wallet address
      appStore.wallet = getMetaMaskWallet() || getConnectWallet();
      console.log('Wallet: ', appStore.wallet);

      i18next.changeLanguage(config.language);
      i18next.reloadResources();
      navigate('/app');
    }
  }, [config, navigate]);

  const captchaRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    captchaRef &&
      captchaRef.current &&
      (captchaRef.current.src = `/api/captcha.php?${Date.now()}`);
  }, [loginFailureCount]);

  const onWalletRequest = async (wallet: string) => {
    await (wallet === metamask ? onMetaMaskConnect() : onWalletConnect());
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <img
        src="/assets/knowins-slogan.svg"
        className={classes.slogan}
        alt="who knows win"
      />
      <Typography align="center" variant="h4" component="h4">
        {t('login.welcome_back')}
      </Typography>
      <Typography
        style={{ marginBottom: 0 }}
        align="center"
        color="textSecondary"
        variant="subtitle1"
        component="p"
        paragraph
      >
        {t('login.no_crypto_wallet')}{' '}
        <Link component={RouterLink} to="/sign-up">
          {t('register.title')}
        </Link>
      </Typography>
      <Typography
        align="center"
        color="textSecondary"
        variant="subtitle1"
        component="p"
        paragraph
      >
        <Link component={RouterLink} to="/forgot-password">
          {t('login.forgot_password')}{' '}
        </Link>
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IconButton aria-label="mm" onClick={() => onWalletRequest(metamask)}>
          <img
            style={{ width: 26, height: 23 }}
            src="/assets/mm.svg"
            alt="MetaMask"
          />
        </IconButton>
        <MetaMask />
        <IconButton
          aria-label="wc"
          onClick={() => onWalletRequest(walletconnect)}
        >
          <img
            style={{
              backgroundColor: '#cc008c',
              padding: 3,
              borderRadius: 3,
              transform: 'scale(0.9)',
            }}
            src="/assets/wc.png"
            alt="Wallet Connect"
          />
        </IconButton>
        <WalletConnect
          title={t('wallet_connect.login')}
          action={t('action.scan_n_login')}
          instruction={t('action.login_qr_instruction')}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              margin="dense"
              required
              fullWidth
              label={t('user.email')}
              type="email"
              variant="standard"
              size="small"
            />
          )}
        />
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              margin="dense"
              fullWidth
              label={t('user.account_name')}
              variant="standard"
              placeholder="default"
              size="small"
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              margin="dense"
              required
              fullWidth
              label={t('user.password')}
              // type="password"
              autoComplete="current-password"
              variant="standard"
              size="small"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        {loginFailureCount > 0 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Controller
              name="captcha"
              control={control}
              render={({ field }) => (
                <FormControl margin="dense" style={{ flex: '1 1 auto' }}>
                  <InputLabel htmlFor="captcha-input">
                    {t('login.captcha')}
                  </InputLabel>
                  <Input
                    {...field}
                    id="captcha-input"
                    aria-describedby="captcha-helper-text"
                  />
                  <FormHelperText id="my-helper-text">
                    {t('login.captcha_text')}
                  </FormHelperText>
                </FormControl>
              )}
            />
            <img
              src="/api/captcha.php"
              ref={captchaRef}
              alt={t('login.captcha')}
              className={classes.captchaImage}
            />
          </Box>
        )}
        <LoadingButton
          sx={{ m: '1rem 0 0 0' }}
          fullWidth
          variant="contained"
          loading={status === 'loading'}
          disabled={status === 'loading'}
          loadingPosition="start"
          startIcon={<LoginSharpIcon />}
          type="submit"
        >
          {t('login.title')}
        </LoadingButton>
      </form>
    </Container>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(3, 3),
    textAlign: 'center',
  },
  slogan: {
    width: theme.spacing(88),
  },
  captchaImage: {
    width: '129px',
    height: '37px',
    objectFit: 'cover',
    marginLeft: theme.spacing(1),
    borderRadius: '8px',
  },
}));

export default Login;
