import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Paper,
  Stepper,
  Step,
  StepLabel,
  TextField,
  InputLabel,
  Input,
  Popover,
} from '@mui/material';
import PlusIcon from '@mui/icons-material/AddSharp';
import DeleteIcon from '@mui/icons-material/CloseSharp';
import Bid from '@mui/icons-material/SendSharp';
import Grid from '@mui/material/Unstable_Grid2';
import {
  DataGrid,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridRenderEditCellParams,
  GridToolbarQuickFilter,
  MuiEvent,
  useGridApiContext,
  useGridApiRef,
} from '@mui/x-data-grid';
import { useDropzone } from 'react-dropzone';
// import ScanConnect from 'components/Payment/ScanConnect';
import { error } from '@knowins/notifier';
import CountrySelect from 'components/Element/CountrySelect';
import CountrySelectEditor from 'components/Element/CountrySelectEditor';
import DateRangeSelectEditor from 'components/Element/DateRangeSelectEditor';
import { readLocalStorage } from 'helper/utils';

const AppAd = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [images, setImages] = useState({});
  const [airtime, setAirtime] = useState<
    Array<{ id: number | string; [key: string]: any }>
  >([
    {
      id: '00',
      country: 'All Countries',
      date: new Date().toJSON().slice(0, 10),
      time: '0:00AM - 11:59PM',
      slot: t('ad.available'),
      price: 0.1,
    },
  ]);

  const changeImage = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const steps = [
    t('ad.upload_content'),
    t('ad.pick_airtime'),
    t('ad.total'),
    t('ad.pay'),
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [value, setValue] = React.useState('');
  const apiRef = useGridApiRef();

  const handlePopoverOpen = (
    params: GridCellParams,
    event,
    details: GridCallbackDetails
  ) => {
    const { field, id, row } = params;
    if (['date', 'country', 'price'].includes(field)) {
      apiRef.current.startCellEditMode({ id, field });
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  const onRemove = (name: string) => {
    setImages((prev) =>
      Object.keys(prev).reduce((acc, v) => {
        if (v !== name) {
          acc[v] = prev[v];
        }
        return acc;
      }, {})
    );
  };

  const isStepOptional = (step: number) => {
    return step === -1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleSelect = (data) => {
    console.log('.......data', data);
  };

  const handleCountryChange = (data) => {
    console.log('.......data', data);
  };

  const columns = useMemo(
    (): GridColDef[] => [
      {
        field: 'country',
        headerName: t('ad.country'),
        width: 118,
        editable: true,
        renderEditCell: (params) => {
          console.log(params);
          return <CountrySelectEditor {...params} />;
        },
      },
      {
        field: 'date',
        headerName: t('common.time.Date'),
        width: 98,
        editable: true,
        renderEditCell: (params: GridRenderEditCellParams) => (
          <DateRangeSelectEditor {...params} />
        ),
      },
      // {
      //   field: 'time',
      //   headerName: t('common.time.Time'),
      //   width: 138,
      // },
      {
        field: 'slot',
        headerName: t('ad.slot'),
        flex: 3,
      },
      {
        field: 'tx',
        headerName: t('ad.tx'),
        width: 118,
      },
      {
        field: 'price',
        headerName: t('ad.price'),
        width: 118,
        editable: true,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: GridRenderEditCellParams) => (
          <>{`$ ${params.value}`} </>
        ),
      },
    ],
    [t]
  );

  const handleReset = () => {
    setActiveStep(0);
  };

  const calculateTotalPrice = () => {};

  const Setup = (props) => {
    const { open, setOpen } = props;
    const onDrop = useCallback((acceptedFiles) => {
      if (acceptedFiles.length > 2) {
        return error('template.got_max_no_of_image');
      }
      const { moneta = '' } = readLocalStorage();

      acceptedFiles.forEach((file) => {
        const { name, path, size, type } = file;
        if (
          !['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(type)
        ) {
          return error(t('status.file_type_not_accepted') + `: ${type}`);
        }

        if (size > 2 * 1024 * 1024) {
          return error('status.file_zise_not_accepted');
        }

        console.log('file...', file);
        const cacheName = {};

        // Refer to: https://cloudinary.com/documentation/image_upload_api_reference#upload
        if (!images[name] && !cacheName[name]) {
          cacheName[name] = true;
          const data = new FormData();
          data.append('file', file);
          data.append('upload_preset', 'knowins');
          data.append('cloud_name', 'dsaw7i5t3');
          // data.append('format', 'jpg');
          // data.append('eager', [{format: 'jpg'}, {max_width: 1000}])
          data.append('folder', moneta);
          setUploading(true);
          fetch('https://api.cloudinary.com/v1_1/dsaw7i5t3/image/upload', {
            method: 'post',
            body: data,
          })
            .then((resp) => resp.json())
            .then((data) => {
              setImages((prev) => ({
                ...prev,
                [name]: { path, size, type, url: data.secure_url },
              }));
              setUploading(false);
            })
            .catch((err) => {
              setUploading(false);
              console.log(err);
            });
        }
      });
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
    });

    return (
      <Modal
        open={open}
        onClose={handleClose}
        // slots={{ backdrop: Backdrop }}
        // slotProps={{
        //   backdrop: {
        //     timeout: 500,
        //   },
        // }}
      >
        {/* <Fade in={open}> */}
        <Paper
          elevation={7}
          // align="left"
          sx={{
            ...{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 838,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              pt: 2,
              px: 4,
              pb: 3,
            },
            // width: 300,
            position: 'relative',
            // paddingLeft: '34px !important',
            // paddingRight: '34px !important',
            // paddingTop: `${paddingTop}px !important`,
            // paddingBottom: `${paddingBottom}px !important`,
            // marginTop: `${marginTop}px !important`,
            borderRadius: '12px',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Box sx={{ marginTop: 12 }}>
              {activeStep === 0 && (
                <React.Fragment>
                  <Grid
                    style={{ margin: 6 }}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={8}
                  >
                    <Grid
                      style={{
                        // ['--b' as any]: '4px',
                        // width: 68,
                        // height: 68,
                        // aspectRatio: 1,
                        // padding: 0, // 18,
                        // margin: '18px 28px 18px 18px',
                        // background: `
                        //   conic-gradient(from 90deg at var(--b) var(--b),transparent 90deg,#cc008c 0)
                        //   calc(100% + var(--b)/2) calc(100% + var(--b)/2)/
                        //   calc(50%  + var(--b))   calc(50%  + var(--b))`,
                        padding: '2rem',
                        display: 'flex',
                      }}
                      {...getRootProps()}
                    >
                      <IconButton
                        aria-label="add"
                        size="small"
                        color="primary"
                        disabled={uploading}
                      >
                        <PlusIcon
                          style={{ width: 68, height: 68 }}
                          fontSize="small"
                          viewBox="0 0 24 24"
                        />
                      </IconButton>
                      <input {...getInputProps()} />
                    </Grid>
                    {Object.keys(images).map((name) => (
                      <Grid
                        key={name}
                        style={{
                          width: 138,
                          height: 138,
                          margin: 3,
                          backgroundImage: "url('" + images[name].url + "')",
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          position: 'relative',
                        }}
                      >
                        <IconButton
                          sx={{
                            m: 0,
                            p: 0,
                            top: 0,
                            right: 0,
                            position: 'absolute',
                            color: 'rgb(154, 160, 166)',
                          }}
                          aria-label="delete"
                          onClick={(
                            (n) => () =>
                              onRemove(n)
                          )(name)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    ))}
                  </Grid>
                  <InputLabel>{t('ad.name')}</InputLabel>
                  <Input name="name" aria-describedby="captcha-helper-text" />
                  <TextField
                    name="link"
                    margin="dense"
                    // required
                    fullWidth
                    label={t('URL')}
                    type="email"
                    variant="standard"
                    size="small"
                  />
                </React.Fragment>
              )}
            </Box>
            {activeStep === 1 && (
              <Box
                sx={{
                  flex: '1 1 0%',
                  height: 388,
                }}
              >
                <CountrySelect
                  sx={{ marginBottom: '6px' }}
                  fieldLabel="ad.select_country"
                  fieldStype={{ maxWidth: 188, labelAlign: 'left' }}
                  handleSelect={handleCountryChange}
                />
                <DataGrid
                  apiRef={apiRef}
                  rows={airtime.map((r) => ({
                    ...r,
                    id: r.id,
                  }))}
                  density="compact"
                  columns={columns}
                  autoPageSize
                  checkboxSelection={true}
                  disableRowSelectionOnClick
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  onCellClick={handlePopoverOpen}
                  // slotProps={{
                  //   cell: {
                  //     onMouseEnter: handlePopoverOpen,
                  //     onMouseLeave: handlePopoverClose,
                  //   },
                  // }}
                />
                <Popover
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={openPopover}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Typography
                    sx={{ p: 1 }}
                  >{`${value.length} characters.`}</Typography>
                </Popover>
              </Box>
            )}
            {activeStep === 2 && (
              <>
                <Typography>You have selected to</Typography>
                <ul>
                  <li>
                    <Typography>Outbid the current air slots</Typography>
                    <ol>
                      <li>None</li>
                    </ol>
                  </li>
                  <li>
                    <Typography>Bid the available air slots</Typography>
                    <ol>
                      <li>from 08-18-2023 for 5 days at $5.00</li>
                      <li>
                        Name your own price. To avoid being outbid you may want
                        to set the higher bid price.
                      </li>
                      <li>
                        If someone wants to outbid you, he/she would have to pay
                        premium on top of your bid price. 60% of premium amount
                        goes to you as profit.
                      </li>
                    </ol>
                  </li>
                </ul>
                <Typography>The total price is: $5.00</Typography>
              </>
            )}
            {/*activeStep === 3 && <ScanConnect open={false} setOpen={setOpen} />*/}
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {/* <Typography sx={{ mt: 2, mb: 1 }}>
                    Step {activeStep + 1}
                  </Typography> */}
                <Box
                  sx={{ display: 'flex', flexDirection: 'row', mt: 8, pt: 2 }}
                >
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t('action.back')}
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      {t('action.skip')}
                    </Button>
                  )}
                  {activeStep < steps.length - 1 && (
                    <Button onClick={handleNext}>{t('action.next')}</Button>
                  )}
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Paper>
        {/* </Fade> */}
      </Modal>
    );
  };

  return (
    <Box
      style={{
        position: 'absolute',
        left: 38,
        top: 38,
        height: 'calc(100vh - 96px)',
        width: 'calc(100vw - 339px)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: 'url(assets/ad.jpeg)',
        display: 'flex',
      }}
    >
      <Setup open={open} setOpen={setOpen} />
      <Box
        sx={{
          position: 'relative',
          top: 9,
          left: 0,
          height: 16,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {/* <Typography style={{ marginTop: 0, paddingLeft: 1, fontSize: 11 }}>
          {t('ad.do_not_like_me')}?
        </Typography> */}
        <Button
          sx={{ fontSize: 9 }}
          variant="outlined"
          size="small"
          onClick={changeImage}
          disabled
        >
          {t('ad.replace_me')}...
        </Button>
      </Box>
    </Box>
  );
};

export default AppAd;
