import { useRef, useLayoutEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Paper,
  Stepper,
  Step,
  StepLabel,
  TextField,
  InputLabel,
  Input,
  Popover,
} from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';

export default function DateRangeSelect(props: GridRenderEditCellParams) {
  const { id, value, field, hasFocus } = props;

  const apiRef = useGridApiContext();
  const ref = useRef<HTMLElement>();
  useEnhancedEffect(() => {
    if (hasFocus && ref.current) {
      const input = ref.current.querySelector<HTMLInputElement>(
        `input[value="${value}"]`
      );
      input?.focus();
    }
  }, [hasFocus, value]);

  const handleValueChange = (newValue) => {
    apiRef.current.stopCellEditMode({ id, field });
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue.toJSON().slice(0, 10),
    });
  };

  return (
    <Box
      sx={{
        ...{
          position: 'absolute',
          top: '0%',
          left: '50%',
          transform: 'translate(-50%, 0%)',
          width: 368,
          height: 368,
          bgcolor: 'background.paper',
          border: '1px solid #cc008c',
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
        },
      }}
    >
      <DateCalendar showDaysOutsideCurrentMonth onChange={handleValueChange} />
    </Box>
  );
}
