import { Box, Autocomplete, TextField, InputLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { useTranslation } from 'react-i18next';
import { useGetCountriesQuery } from 'features/FrmUtils';

const CountrySelect = (props) => {
  const { t } = useTranslation();
  const {
    name,
    value,
    fieldLabel,
    fieldStype = {},
    freeSolo = true,
    allowEmpty,
    noTranslation,
    handleSelect,
    handleChange,
    sx,
  } = props;
  const {
    padding,
    minWidth = 0,
    maxWidth = '100%',
    labelWidth = '150px',
    labelSeparator = ' ',
    labelAlign = 'right',
    float,
  } = fieldStype;

  const { data: countries = [] } = useGetCountriesQuery();
  const style = float ? { float, flex: 'inherit' } : {};
  // console.log('style', style);

  // const isOptionEqualToValue = (option, value) => {
  //   if (option === value) {
  //     return true;
  //   }

  //   if (allowEmpty && !value) {
  //     return true;
  //   }

  //   const idx = countries.findIndex((v) => v === option);
  //   if (idx === -1) {
  //     return false;
  //   }

  //   return value
  //     ? stores[fieldOption][idx][0] === value
  //     : stores[fieldOption][idx][0] === '';
  // };

  // console.log('maxWidth:', name, maxWidth);

  const onBlur = (value) => {
    if (typeof handleSelect === 'function') {
      const selected = countries.find((c) => c.printable_name === value);
      handleSelect(selected);
    }
  };

  const onChange = (value) => {
    console.log(value);
    if (typeof handleChange === 'function') {
      const selected = countries.find((c) => c.printable_name === value[0]);
      handleChange(selected);
    }
  };

  return (
    <Grid container xs style={style} sx={sx}>
      <Grid
        xs="auto"
        sx={{ width: labelWidth, marginTop: '0.9px', marginBottom: '0.9px' }}
      >
        <InputLabel
          id={`react-combo-${name}`}
          sx={{ w: labelWidth, textAlign: labelAlign, top: '8px' }}
        >
          {t(fieldLabel)}
        </InputLabel>
      </Grid>
      {labelWidth > 0 && <Grid xs="auto">&nbsp;</Grid>}
      <Grid xs alignItems="end">
        <Autocomplete
          selectOnFocus
          freeSolo={freeSolo}
          sx={{
            // backgroundColor: viewState?.readOnly ? '#f0f8ff' : '#ffffff',
            // borderColor: viewState?.readOnly ? '#d0d0d0' : 0,
            minWidth: `${minWidth}px`,
            maxWidth: `${maxWidth == '100%' ? maxWidth : maxWidth + 'px'}`,
          }}
          id={`react-combo-${name}`}
          disableClearable
          // readOnly={viewState?.readOnly}
          size="small"
          options={countries.map((c) => [c.printable_name])}
          getOptionLabel={(o) => o[0]}
          // isOptionEqualToValue={isOptionEqualToValue}
          onChange={(e, v) => onChange(v)}
          // onInputChange={handleInputChange}  // DO NOT use this. The input will be rerender
          defaultValue={value}
          renderInput={(params) => {
            const { InputProps, ...others } = params;
            return (
              <TextField
                {...others}
                variant="outlined"
                margin="none"
                InputProps={{
                  ...InputProps,
                  type: 'search',
                }}
                onBlur={(e) => onBlur(e.target.value)}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CountrySelect;
