import { useEffect, useRef, useState, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LoginIcon from '@mui/icons-material/LoginSharp';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Unstable_Grid2';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import QRCode from 'react-qr-code';
import CopyToClipboard from 'react-copy-to-clipboard';

import { info } from '@knowins/notifier';
import store from './store/';

const WalletConnect = (props: any) => {
  const { t } = useTranslation();

  const state = useSnapshot(store);

  const onClick = async () => {
    info('status.qr_code_copied');
  };

  const handleClose = () => {
    store.open = false;
  };

  const handleSelect = (data) => {
    store.selected = data.moneta;
  };

  const columns = useMemo(
    (): GridColDef[] => [
      {
        field: 'moneta',
        headerName: t('wallet_connect.org'),
        flex: 1,
        // headerClassName: {},
      },
      {
        field: 'fullname',
        headerName: t('wallet_connect.fullname'),
        flex: 3,
      },
      {
        field: 'action',
        headerName: '',
        width: 68,
        renderCell: (params: any) => (
          <IconButton
            aria-label="login"
            onClick={() => handleSelect(params.row)}
          >
            <LoginIcon />
          </IconButton>
        ),
      },
    ],
    [t]
  );

  if (!state.open) {
    return null;
  }

  return (
    <Dialog onClose={handleClose} open={state.open} fullWidth>
      <DialogTitle sx={{ m: 0, p: 0, backgroundColor: '#cc008c' }}>
        <Grid xs display="flex" justifyContent="center" alignItems="center">
          <Typography sx={{ lineHeight: 2.8 }} color="secondary">
            {props.title}
          </Typography>
        </Grid>
      </DialogTitle>
      <Box width="100%" height="100%">
        {state.authorization.length > 0 ? (
          <Box
            sx={{
              flex: '1 1 0%',
              height: 280,
            }}
          >
            <DataGrid
              rows={state.authorization?.map((r) => ({
                ...r,
                id: r.moneta,
              }))}
              columns={columns}
              autoPageSize
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          </Box>
        ) : (
          <Grid
            container
            direction="column"
            alignContent="center"
            alignItems="center"
            sx={{ borderRadius: 24, padding: '1.3em' }}
          >
            <Grid
              xs={12}
              justifyContent="center"
              alignItems="center"
              sx={{
                display: 'flex',
                width: 160,
                height: 160,
                border: '1px solid',
                borderRadius: 3,
              }}
            >
              <CopyToClipboard text={state.uri}>
                <QRCode size={128} value={state.uri} onClick={onClick} />
              </CopyToClipboard>
            </Grid>
            <Typography
              variant="body1"
              color="primary"
              fontWeight={700}
              textAlign="center"
              fontSize="1.3em"
              sx={{ pt: 8 }}
            >
              {props.action}
            </Typography>
            <Grid
              justifyContent="center"
              alignItems="center"
              sx={{ pt: 8, display: 'flex' }}
            >
              <Typography
                sx={{
                  display: 'inline-block',
                  pr: 2,
                }}
              >
                {props.instruction}
              </Typography>
              <img
                style={{ width: 21, height: 12 }}
                src="/assets/wallet_connect.svg"
                alt="MetaMask"
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Dialog>
  );
};

export default WalletConnect;
